import React, {Component} from 'react';
import './App.css';
import {injectStripe} from 'react-stripe-elements';
import zefiroLogo from './media/zefiro.jpg';
import moment from 'moment';
import Language from './Language';


class CheckStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {"loading": true, "data": {}};
        this.TooglePlan = this.TooglePlan.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }


    TooglePlan(subId, act, livemode) {


        this.setState({loading: true});

        let response = window.confirm(Language.get("aresure"));
        if (response) {

            fetch('https://serverless.zefiroapp.com/stripe', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    env: livemode ? "live" : "debug",
                    subId: subId,
                    act: 'UpdateSingleSub',
                    cancel_at_period_end: act === "deActivate",
                })
            }).then(response => response.json())
                .then(data => {
                    this.setState({loading: false});

                    this.refreshData();
                });

        }
        else {
            this.setState({newDataLoading: false});
        }
        //deActivate
        //reActivate
        //alert(act);
        //alert(subId);
    }

    refreshData()
    {
        this.setState({loading: true});

        fetch('https://serverless.zefiroapp.com/stripe', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                env: this.props.env,
                act: 'ActiveSubscriptionsList',
                value: this.props.match[2],
            })
        }).then(response => response.json())
            .then(data => {
                if(data.body !== "")
                {
                    this.setState({loading: false, data: data});
                }

            });
    }

    componentDidMount() {
       this.refreshData();
    }


    render() {
        let subscriptions = [];
        try {
            if (this.state.data.body !== undefined) {
                subscriptions = this.state.data.body;
            }
        }
        catch (err) {
        }


        return (
            <div className="checkout">
                <div className={"shopTitle"}>
                    <img alt={"Zefiro"} src={zefiroLogo} width={"25px"} style={{marginRight: '10px'}}/>
                    <div>Zefiro Subscription</div>
                </div>

                {subscriptions.map((obj, index) => {

                    try {

                        let status = 'active';
                        let SwitchToButton = "";
                        let ToogleButton = "";
                        let NextDate = "";

                        try {
                            if (obj.status === "active") {
                                if (obj.cancel_at_period_end === true) {
                                    status = "nextToExpire";
                                    ToogleButton = "reActivate";
                                }
                            }
                            if (obj.status !== "active") {
                                status = "expired";
                                return null;
                            }
                            NextDate = moment(obj.current_period_end * 1000).format("YYYY/MM/DD");

                            if (status === 'active') {
                                ToogleButton = "deActivate";

                                /* if (obj.interval !== "year") {
                                     SwitchToButton = "switch_to_annual";
                                 }*/
                            }
                        }
                        catch (err) {
                            console.log(err);
                        }

                        return <div style={{...style.box}} key={index}>
                            <div style={{...style.InnerBox}}>
                                <div style={{...style.innerTitle}}>{Language.get("Titolo")}</div>
                                <div style={{...style.innerValue}}>{obj.plan.nickname}</div>
                            </div>


                            {status === "active" ?
                                <div style={{...style.InnerBox}}>
                                    <div style={{...style.innerTitle}}>{Language.get("Rinnovo")}</div>
                                    <div style={{...style.innerValue}}>{NextDate}</div>
                                </div> : null}

                            {status === "nextToExpire" ?
                                <div style={{...style.InnerBox}}>
                                    <div style={{...style.innerTitle}}>{Language.get("Scadenza")}</div>
                                    <div style={{...style.innerValue}}>{NextDate}</div>
                                </div> : null}


                            <div style={{...style.InnerBox}}>
                                <div style={{...style.innerTitle}}>{Language.get("Stato")}</div>
                                <div style={{...style.innerValue}}>{obj.status}</div>
                            </div>


                            <div style={{...style.InnerBox, marginTop: '15px'}}>
                                <div style={{...style.innerValueButton}}>
                                    {SwitchToButton !== "" ?
                                        <div onClick={() => {
                                            let app_id = "";
                                            try {
                                                let regex = /manage\/([0-9.]+)\/status/;
                                                let result = window.location.href.match(regex);
                                                if (result === null) {
                                                    return;
                                                }
                                                app_id = result[1];
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }

                                            let mainLink = "/app/" + app_id + "/confirm?d=";

                                            window.location.href = mainLink + btoa(JSON.stringify({"from": SwitchToButton}));
                                        }} style={{...style.innerValueButtonSingle}}>{SwitchToButton}</div> : null}
                                    {ToogleButton !== "" ?
                                        <div
                                            onClick={() => {
                                                this.TooglePlan(obj.id, ToogleButton, obj.livemode);
                                            }}
                                            style={{...style.innerValueButtonSingle}}>{Language.get(ToogleButton)}</div> : null}
                                </div>
                            </div>


                        </div>
                    }
                    catch
                        (err) {
                        console.log(err);
                        return null;
                    }

                    //  return <div key={index}>ss</div>
                })}


                {this.state.loading ? <div className={"spinnerBg"}/> : null}
                {this.state.loading ? <div className="spinner">
                    <div className="double-bounce1"/>
                    <div className="double-bounce2"/>
                </div> : null}

            </div>
        );
    }
}

export default injectStripe(CheckStatus);


const
    style = {
        box:
            {
                border: '1px solid rgba(10,10,10,0.2)',
                margin: '15px',
                padding: '10px',
                flexBasis: '200px',
                borderRadius: '5px'
            },
        InnerBox:
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                fontSize: '12px',
                marginBottom: '5px'
            },
        innerValue:
            {
                flexBasis: '100%',
                textAlign: 'left',
                marginLeft: '10px'
            },
        innerTitle:
            {
                textAlign: 'left',
                width: '110px',
                fontWeight: 'bold'
            },
        innerValueButton:
            {
                marginLeft: '10px',
                flexBasis: '100%',
                textAlign: 'left',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            },
        innerValueButtonSingle: {
            cursor: 'pointer',
            fontWeight: 'bold',
            color: '#3abced',
            margin: '12px'
        }
    };