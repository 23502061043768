import React, {Component} from 'react';
import './App.css';
import {injectStripe, CardElement} from 'react-stripe-elements';
import zefiroLogo from './media/zefiro.jpg';
import Language from './Language';


class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {"loading": true, "data": {}};
        this.submit = this.submit.bind(this);

    }

    componentDidMount() {
        //retrieve current cardd

        fetch('https://serverless.zefiroapp.com/stripe', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                env: this.props.env,
                act: 'getUserInformation',
                value: this.props.match[2],
            })
        }).then(response => response.json())
            .then(data => {
                if(data.body !== "")
                {
                    this.setState({loading: false, data: data});
                }

            });
    }


    async submit(ev) {

        let tokenId = "";

        try {
            let {token} = await this.props.stripe.createToken();
            tokenId = token.id;
        }
        catch (err) {
            //console.log(err);
        }


        if(tokenId === "")
        {
            return;
        }
        this.setState({loading: true});

        fetch('https://serverless.zefiroapp.com/stripe', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                env: this.props.env,
                act: 'updateUserInformation',
                value: this.props.match[2],
                tokenid: tokenId
            })
        }).then(response => response.json())
            .then(data => {
                this.setState({loading: false});

            });




    }



    render() {




        return (
            <div className="checkout">
                <div className={"shopTitle"}>
                    <img alt={"Zefiro"} src={zefiroLogo} width={"25px"} style={{marginRight: '10px'}}/>
                    <div>Zefiro Checkout</div>
                </div>

                {Language.get("CambiaCarta")}
                <CardElement/>

                <button className={"confirmButton"} onClick={this.submit}>{Language.get("Conferma")}</button>

                {this.state.loading ? <div className={"spinnerBg"}/> : null}
                {this.state.loading ? <div className="spinner">
                    <div className="double-bounce1"/>
                    <div className="double-bounce2"/>
                </div> : null}

            </div>
        );
    }
}

export default injectStripe(UserProfile);
