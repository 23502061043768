import React, {Component} from 'react';
import './App.css';
import {injectStripe, CardElement} from 'react-stripe-elements';
import zefiroLogo from './media/zefiro.jpg';
import Language from './Language';


class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.state = {"loading": true, "data": {}};

    }

    componentDidMount() {

        fetch('https://serverless.zefiroapp.com/stripe', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                act: 'GetActiveBagForUser',
                value: this.props.match[2],
            })
        }).then(response => response.json())
            .then(data => {

                try {
                    if (data.body.status === "incomplete") {
                        this.submit();
                        return;
                    }

                    if (data.body.status === "active") {
                        //window.location.href = "zefiro/complete/1568033885932?"; //numero da verificare
                        window.location.href = "/zefiro/complete/" + this.props.match[2];
                        return;
                    }

                    this.setState({loading: false, data: data});
                }
                catch (err) {
                }

            });
    }

    async submit(ev) {

        this.setState({loading: true});

        let tokenId = "";

        try {
            let {token} = await this.props.stripe.createToken();
            tokenId = token.id;
        }
        catch (err) {
        }


        fetch('https://serverless.zefiroapp.com/stripe', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                act: 'ProcessPaymentFor',
                value: this.props.match[2],
                tokenId: tokenId
            })
        }).then(response => response.json())
            .then(data => {
                this.setState({loading: false});


                if (data.body.status === true) {
                    //redirect done
                    //window.location.href = "zefiro/complete/1568033885932?";
                    window.location.href = "/zefiro/complete/" + this.props.match[2];

                }

                if (data.body.status === false) {

                    if (data.body.reason === "incomplete") {
                        if (data.body.action.action === true) {
                            if (data.body.action.redirect !== "") {
                                window.location.href = data.body.action.redirect;
                            }
                        }

                    }
                    else
                    {
                        alert(Language.get("errore"));
                    }
                }

            });

    }

    render() {
        let loaded = false;

        let total_string = "";
        let total_currency = "";
        let description = "";
        let stripeCustomedId = "";

        try {
            total_string = (this.state.data.body.total_string);
            total_currency = this.state.data.body.total_currency;
            description = this.state.data.body.bag.description;

            if(this.state.data.body.stripeCustomedId !== undefined)
            {
                stripeCustomedId = this.state.data.body.stripeCustomedId;
            }


            loaded = true;
        }
        catch (err) {
        }


        try {
            if (this.state.data.body.app === undefined) {
                return <div>{Language.get("errore")}</div>;
            }
        }
        catch (err) {
        }



        return (
            <div className="checkout">
                <div className={"shopTitle"}>
                    <img alt={"Zefiro"} src={zefiroLogo} width={"25px"} style={{marginRight: '10px'}}/>
                    <div>Zefiro Checkout</div>
                </div>

                <div className={"checkoutItem"}>
                    {description}
                </div>

                <div className={"value"}>
                    {total_string} {total_currency}
                </div>


                {loaded && stripeCustomedId === "" ? <CardElement/> : null}
                <button className={"confirmButton"} onClick={this.submit}>{Language.get("Conferma")}</button>

                {this.state.loading ? <div className={"spinnerBg"}/> : null}
                {this.state.loading ? <div className="spinner">
                    <div className="double-bounce1"/>
                    <div className="double-bounce2"/>
                </div> : null}

            </div>
        );
    }
}

export default injectStripe(CheckoutForm);
