import React, {Component} from 'react';
import './App.css';
import zefiroLogo from './media/zefiro.jpg';
import Language from './Language';


class Complete extends Component {


    render() {


        return (
            <div className="checkout">
                <div className={"shopTitle"}>
                    <img alt={"Zefiro"} src={zefiroLogo} width={"25px"} style={{marginRight: '10px'}}/>
                    <div>Zefiro Checkout</div>
                </div>


                {Language.get("thankyou")}

            </div>
        );
    }
}

export default Complete;
