import React, {Component} from 'react';
import './App.css';
import zefiroLogo from './media/zefiro.jpg';
import Language from './Language';


class Action extends Component {

    constructor(props) {
        super(props);
        this.state = {loading: true, data: {}};

        this.submit = this.submit.bind(this);
        console.log(this.props);
        console.log(this.props.match[2]);
    }

    componentDidMount() {
        fetch('https://serverless.zefiroapp.com/stripe', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                act: 'actionCheck',
                email: this.props.match[2],
            })
        }).then(response => response.json())
            .then(data => {

                if(data.body.type === "auto")
                {
                    window.location.href = data.body.redirect;
                    return;
                }

                this.setState({loading: false, data: data.body});
            });

    }

    submit()
    {
        window.location.href = this.state.data.redirect;
    }

    render() {


        if(this.state.data.action === false)
        {
            return (
                <div className="checkout">
                    <div className={"shopTitle"}>
                        <img alt={"Zefiro"} src={zefiroLogo} width={"25px"} style={{marginRight: '10px'}}/>
                        <div>Zefiro Checkout</div>
                    </div>


                    {Language.get("errore")}


                </div>
            );
        }


        return (
            <div className="checkout">
                <div className={"shopTitle"}>
                    <img alt={"Zefiro"} src={zefiroLogo} width={"25px"} style={{marginRight: '10px'}}/>
                    <div>Zefiro Checkout</div>
                </div>


                {Language.get("action_required")}
                <button className={"confirmButton"} onClick={this.submit}>{Language.get("Conferma")}</button>


                {this.state.loading ? <div className={"spinnerBg"}/> : null}
                {this.state.loading ? <div className="spinner">
                    <div className="double-bounce1"/>
                    <div className="double-bounce2"/>
                </div> : null}

            </div>
        );
    }
}

export default Action;
