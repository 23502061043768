class Language {


    static get(rif) {

        let current = "en";
        try {
            const userLang = navigator.language || navigator.userLanguage;
            if (userLang.indexOf("it") === 0) {
                current = "it";
            }
        }
        catch (err) {
        }


        let languageMap = {};

        languageMap['deActivate'] = {};
        languageMap['deActivate']['it'] = "Disattiva";
        languageMap['deActivate']['en'] = "Deactivate";

        languageMap['reActivate'] = {};
        languageMap['reActivate']['it'] = "Attiva";
        languageMap['reActivate']['en'] = "Activate";


        languageMap['Titolo'] = {};
        languageMap['Titolo']['it'] = "Titolo";
        languageMap['Titolo']['en'] = "Title";


        languageMap['Rinnovo'] = {};
        languageMap['Rinnovo']['it'] = "Rinnovo";
        languageMap['Rinnovo']['en'] = "Renewal";


        languageMap['Stato'] = {};
        languageMap['Stato']['it'] = "Stato";
        languageMap['Stato']['en'] = "Status";

        languageMap['Conferma'] = {};
        languageMap['Conferma']['it'] = "Conferma";
        languageMap['Conferma']['en'] = "Confirm";

        languageMap['CambiaCarta'] = {};
        languageMap['CambiaCarta']['it'] = "Aggiorna la carta di credito";
        languageMap['CambiaCarta']['en'] = "Update the credit card";


        languageMap['thankyou'] = {};
        languageMap['thankyou']['it'] = "Grazie, stiamo processando il tuo ordine.";
        languageMap['thankyou']['en'] = "Thank you, we are processing your order";

        languageMap['aresure'] = {};
        languageMap['aresure']['it'] = "Sei sicuro?";
        languageMap['aresure']['en'] = "Are you sure?";


        languageMap['errore'] = {};
        languageMap['errore']['it'] = "Si è verificato un errore";
        languageMap['errore']['en'] = "An error occured";


        languageMap['action_required'] = {};
        languageMap['action_required']['it'] = "Per completare l'ordine è richiesta un'azione";
        languageMap['action_required']['en'] = "To complete your order, an action is required";


        try{
            return languageMap[rif][current];
        }
        catch (err){}

        return rif;
    }
}

export default Language;