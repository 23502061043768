import React, {Component} from 'react';
import './App.css';
import {StripeProvider, Elements} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import UserProfile from './UserProfile';
import CheckStatus from './CheckStatus';
import Complete from './Complete';
import Action from './Action';
import Cookies from 'universal-cookie';


const cookies = new Cookies();


//https://stripe.com/docs/recipes/elements-react
//https://stripe.com/docs/stripe-js/reference#stripe-create-token
class App extends Component {

    //http://localhost:3000/zefiro/checkout/2053349885390662
    constructor(props) {
        super(props);

        this.state = {validate: true, isLoading: true, data: {}};

        this.env = "live"; //live

        const envTmp = cookies.get('env');
        if (envTmp !== undefined) {
            this.env = envTmp;
        }


        this.req = window.location.href;
        this.match = null;
        this.type = "";

        if (this.match === null) {

            this.match = this.req.match(/([a-z]+)\/complete\/(.+)/);
            if (this.match !== null) {
                this.type = "complete";
            }
        }


        /*
        if (this.match === null) {
            this.match = this.req.match(/([a-z]+)\/auth\/(.+)\/(.+)/);
            if (this.match !== null) {


                let authObj = {};
                authObj['from'] = 'https://www.google.it/';
                authObj['to'] = 'http://localhost:3000/zefiro/checkout/eyJlbWFpbCIgOiAiYW5kcmVhNzg4N0BnbWFpbC5jb20ifQ==';
                authObj['email'] = 'andrea7887@gmail.com';
            }
        }*/


        if (this.match === null) {
            this.match = this.req.match(/([a-z]+)\/(debug|live)/);
            if (this.match !== null) {


                cookies.set('env', this.match[2], {path: '/', maxAge: 43200});

            }
        }

        if (this.match === null) {
            this.match = this.req.match(/([a-z]+)\/checkout\/(.+)/);
            if (this.match !== null) {

                this.type = "checkout";
            }
        }

        if (this.match === null) {
            this.match = this.req.match(/([a-z]+)\/update_profile\/(.+)/);
            if (this.match !== null) {

                this.type = "update_profile";
            }
        }


        if (this.match === null) {
            this.match = this.req.match(/([a-z]+)\/status\/(.+)/);
            if (this.match !== null) {

                this.type = "status";
            }
        }

        //http://localhost:3000/zefiro/sign/andrea7887@gmail.com
        if (this.match === null) {
            this.state = {validate: false, isLoading: false};

            this.match = this.req.match(/([a-z]+)\/sign\/(.+)/);
            if (this.match !== null) {


                fetch('https://serverless.zefiroapp.com/stripe', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        act: 'sign',
                        email: this.match[2]
                    })
                }).then(response => response.json())
                    .then(data => {
                        console.log(data.body);
                    });
            }
        }


        if (this.match === null) {
            this.state = {validate: false, isLoading: false};

            this.match = this.req.match(/([a-z]+)\/action\/(.+)/);
            if (this.match !== null) {

                this.type = "action";

            }
        }
    }

    componentDidMount() {
        /*
                if (this.state.validate && this.state.isLoading) {
                    fetch('https://serverless.zefiroapp.com/stripe', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            act: 'check',
                            token: this.match[2]
                        })
                    }).then(response => response.json())
                        .then(data => {
                            if(data.body === false)
                            {
                                //this.setState({validate: true, isLoading: false, data: false});
                            }
                            else {
                                this.setState({validate: false, isLoading: false, data: data.body});
                            }

                        });


                }*/
    }

    /*
    lingua
gestione abbonamenti attivi
url di ritorno quando completo

pagina checkout - pensare a tutti errori e gestirli?

da zefiro - registrare carrello?

cosa per protezione account?

     */

    render() {
        /*
                if (this.state.validate && this.state.isLoading) {
                    return null;
                }*/

        //checkout
        //status - cambiare carta di credito
        //subscription - vedere / gestire abbonamenti in corso
//http://localhost:3000/zefiro/checkout/2053349885390662

        //oltre a email inserire scadenza link data? o header autenticazione?

        //http://localhost:3000/zefiro/checkout/eyJlbWFpbCIgOiAiYW5kcmVhNzg4N0BnbWFpbC5jb20ifQ==
        //http://localhost:3000/zefiro/update_profile/eyJlbWFpbCIgOiAiYW5kcmVhNzg4N0BnbWFpbC5jb20ifQ==
        //http://localhost:3000/zefiro/status/eyJlbWFpbCIgOiAiYW5kcmVhNzg4N0BnbWFpbC5jb20ifQ==
        //http://localhost:3000/zefiro/complete/eyJlbWFpbCIgOiAiYW5kcmVhNzg4N0BnbWFpbC5jb20ifQ==

        //http://localhost:3000/zefiro/auth/eyJlbWFpbCIgOiAiYW5kcmVhNzg4N0BnbWFpbC5jb20ifQ==/dwedw

        //http://localhost:3000/zefiro/complete/1568033885932?payment_intent=pi_1FH5BjFcVFsUd2euwLYP1HL0&payment_intent_client_secret=pi_1FH5BjFcVFsUd2euwLYP1HL0_secret_WGPlz4kfi1iCMRJ6oNxrqe5o6&source_type=card
        /*

        stato: inserire passa ad annuale e passa direttmanete a checkout con carrello preconfigurato
         */

        let debug = null;

        if (this.env === "debug") {
            debug = <div style={{
                width: '40px',
                height: '40px',
                position: 'fixed',
                top: '10px',
                left: '10px',
                backgroundColor: 'red',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                D</div>
        }


        if (this.type === "checkout") {
            return (
                <div className="App">
                    {debug}
                    <div className={"header"}>
                        <div className={"headerContent"}>
                            <div className={"headerTitle"}>
                                <img alt={"Zefiro"}
                                     src={"https://app.dashboard.zefiroplatform.com/static/media/logo.5876d72c.svg"}
                                     height={"60%"}/>
                            </div>
                        </div>

                    </div>

                    <div className={"headerSpacer"}/>

                    <StripeProvider
                        apiKey={this.env === "debug" ? "pk_test_MPlWkkI80HKuEWXEwPpbxmZL" : "pk_live_VYiUm6D1jIjkuIDmBycmWA0m"}>
                        <div className="content">
                            <Elements>
                                <CheckoutForm type={this.type} data={this.state.data} match={this.match}/>
                            </Elements>
                        </div>
                    </StripeProvider>
                </div>
            );
        }

        if (this.type === "update_profile") {
            return (
                <div className="App">
                    {debug}
                    <div className={"header"}>
                        <div className={"headerContent"}>
                            <div className={"headerTitle"}>
                                <img alt={"Zefiro"}
                                     src={"https://app.dashboard.zefiroplatform.com/static/media/logo.5876d72c.svg"}
                                     height={"60%"}/>
                            </div>
                        </div>

                    </div>

                    <div className={"headerSpacer"}/>


                    <StripeProvider
                        apiKey={this.env === "debug" ? "pk_test_MPlWkkI80HKuEWXEwPpbxmZL" : "pk_live_VYiUm6D1jIjkuIDmBycmWA0m"}>
                        <div className="content">
                            <Elements>
                                <UserProfile env={this.env} type={this.type} data={this.state.data} match={this.match}/>
                            </Elements>
                        </div>
                    </StripeProvider>
                </div>
            );
        }

        if (this.type === "status") {
            return (
                <div className="App">
                    {debug}
                    <div className={"header"}>
                        <div className={"headerContent"}>
                            <div className={"headerTitle"}>
                                <img alt={"Zefiro"}
                                     src={"https://app.dashboard.zefiroplatform.com/static/media/logo.5876d72c.svg"}
                                     height={"60%"}/>
                            </div>
                        </div>

                    </div>

                    <div className={"headerSpacer"}/>

                    <StripeProvider
                        apiKey={this.env === "debug" ? "pk_test_MPlWkkI80HKuEWXEwPpbxmZL" : "pk_live_VYiUm6D1jIjkuIDmBycmWA0m"}>
                        <div className="content">
                            <Elements>
                                <CheckStatus env={this.env} type={this.type} data={this.state.data} match={this.match}/>
                            </Elements>
                        </div>
                    </StripeProvider>

                </div>
            );
        }

        if (this.type === "complete") {
            return (
                <div className="App">
                    {debug}
                    <div className={"header"}>
                        <div className={"headerContent"}>
                            <div className={"headerTitle"}>
                                <img alt={"Zefiro"}
                                     src={"https://app.dashboard.zefiroplatform.com/static/media/logo.5876d72c.svg"}
                                     height={"60%"}/>
                            </div>
                        </div>

                    </div>

                    <div className={"headerSpacer"}/>

                    <div className="content">
                        <Complete env={this.env} type={this.type} data={this.state.data} match={this.match}/>
                    </div>

                </div>
            );
        }


        if (this.type === "action") {
            return (
                <div className="App">
                    {debug}
                    <div className={"header"}>
                        <div className={"headerContent"}>
                            <div className={"headerTitle"}>
                                <img alt={"Zefiro"}
                                     src={"https://app.dashboard.zefiroplatform.com/static/media/logo.5876d72c.svg"}
                                     height={"60%"}/>
                            </div>
                        </div>

                    </div>

                    <div className={"headerSpacer"}/>

                    <div className="content">
                        <Action env={this.env} type={this.type} data={this.state.data} match={this.match}/>
                    </div>

                </div>
            );
        }



        return null; //messaggio errore

    }
}

export default App;

